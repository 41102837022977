.st-input {
  background: var(--st-color-white);
  border: 1px solid var(--st-color-grey);
  border-radius: 2px;
  padding: 14px 16px;
  font-weight: 300;
  color: var(--st-color-black);
  font-size: 16px;
  line-height: 19px;

  @media (max-width: 767px) {
    padding: 12px 16px;
  }

  @media (max-width: 599px) {
    padding: 10px 16px;
    font-size: 14px;
    line-height: 17px;
  }

  &::placeholder {
    color: var(--st-color-grey);
  }

  &:focus {
    border-color: var(--st-color-black);
  }

  &.has-error {
    border-color: var(--st-color-alert);
  }

  &.st-promo {
    border-radius: 2px 0 0 2px;
  }
}

.st-label {
  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
}

.st-radio {
  background: var(--st-color-white);
  cursor: pointer;
  display: block;
  border: 1px solid var(--st-color-black);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin: 0;

  &.active {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: var(--st-color-black);
      margin-left: -5px;
      margin-top: -5px;
    }
  }

  @media (max-width: 767px) {
    width: 20px;
    height: 20px;

    &.active {
      &:after {
        width: 8px;
        height: 8px;
        margin-left: -4px;
        margin-top: -4px;
      }
    }
  }
}

textarea {
  resize: none;
  outline: none;
}
