.st-btn {
  padding: 15px 16px;
  font-size: 14px;
  line-height: 17px;
  border: 1px solid var(--st-color-black);
  background: var(--st-color-white);
  text-transform: uppercase;
  text-align: center;
  outline: none;
  cursor: pointer;
  border-radius: 2px;

  &.full {
    width: 100%;
  }

  &[disabled] {
    cursor: not-allowed;
  }

  @media (max-width: 767px) {
    padding: 14px 16px;
  }

  @media (max-width: 767px) {
    padding: 13px 16px;
  }

  @media (max-width: 599px) {
    font-size: 12px;
    line-height: 15px;
    padding: 12px 25px;
    max-width: none !important;
  }

  &:hover {
    background: var(--st-color-black);
    color: var(--st-color-white);
  }

  &:disabled {
    background: var(--st-color-dark-grey);
    border-color: var(--st-color-dark-grey);
    color: var(--st-color-white);
    pointer-events: none;
  }

  &.st-btn-white {
    background: var(--st-color-white);
    color: var(--st-color-black);
    border-width: 0;

    &:disabled {
      color: var(--st-color-dark-grey);
      pointer-events: none;
    }

    &:hover {
      background: var(--st-color-light-grey-2);
    }
  }

  &.st-btn-dark {
    background: var(--st-color-black);
    color: var(--st-color-white);

    &:disabled {
      background: var(--st-color-dark-grey);
      border-color: var(--st-color-dark-grey);
      color: var(--st-color-white);
      pointer-events: none;
    }

    &:hover {
      background: var(--st-color-dark);
    }
  }

  &.st-btn-transparent {
    background: transparent;

    &:hover {
      background: var(--st-color-black);
      color: var(--st-color-white);
    }
  }

  &.st-btn-md {
    max-width: 285px;
  }

  &.st-btn-sm-md {
    max-width: 267px;
  }

  &.st-btn-xs {
    max-width: 136px;

    @media (max-width: 767px) {
      max-width: 111px;
      min-width: 111px;
    }
  }

  &.st-btn-sm {
    max-width: 260px;
    font-size: 12px;
    line-height: 24px;
    padding: 7px 16px;

    @media (max-width: 767px) {
      padding: 5px 16px;
    }
  }

  &.st-icon-btn {
    padding: 9px;
    border-radius: 2px;
    border-color: var(--st-color-grey);
    font-size: 0;
    line-height: 1;

    &:disabled {
      background: transparent;
    }

    &:hover {
      border-color: var(--st-color-black);
      background: var(--st-color-white);
    }

    @media (max-width: 767px) {
      padding: 12px;
    }

    @media (max-width: 599px) {
      padding: 12px;
      width: 48px;
      height: 48px;
    }
  }

  &.st-promo {
    background: var(--st-color-black);
    color: var(--st-color-white);
    border-radius: 0 2px 2px 0;

    &--applied {
      background: transparent;
      color: var(--st-color-green);
      border-color: var(--st-color-green);
    }

    &:disabled {
      border-color: var(--st-color-grey);
      color: var(--st-color-dark-grey);
      background: var(--st-color-white);
    }
  }
}

.sale-badge {
  background: var(--st-color-white);
  color: var(--st-color-black);
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 9px 30px;
  border: 1px solid var(--st-color-black);
  text-align: center;
  max-width: 129px;
  margin: 0 auto;
  width: 100%;

  &.mobile {
    display: none;
  }

  @media (max-width: 992px) {
    font-size: 12px;
    line-height: 20px;
    padding: 7px 25px;
    max-width: 112px;
    margin: 0 auto 20px;

    @media (max-width: 599px) {
      max-width: none;

      &.mobile {
        display: block;
      }

      &.desktop {
        display: none;
      }
    }
  }

  @media (max-width: 599px) {
    max-width: 150px;
  }

  &.sale {
    background: var(--st-color-yellow);
    color: white;
    border: 0;
  }

  &__long-trial {
    max-width: unset !important;
    width: fit-content !important;
    font-weight: 300 !important;
    text-transform: unset !important;
  }
}

.sale-badge--new {
  border: 1px solid var(--st-color-black);
  color: var(--st-color-black);
  background: var(--st-color-white);
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 2px 8px;
  text-align: center;
  width: max-content;

  &.mobile {
    display: none;
  }

  @media (max-width: 992px) {
    font-size: 10px;
    padding: 2px 6px;
    max-width: 59px;
    margin-bottom: 20px;

    &.sale {
      max-width: unset;
      width: 115px;
    }

    @media (max-width: 599px) {
      &.mobile {
        display: block;
        margin-bottom: 20px !important;
      }

      &.desktop {
        display: none;
      }

      &.sale {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 35px;
      }
    }
  }

  &.sale {
    border: 1px solid var(--st-color-black);
    color: var(--st-color-black);
    background: var(--st-color-white);
  }
}

.btn-wrapper {
  &:has(button[disabled]) {
    cursor: not-allowed;
  }
}
