@mixin image-2x($image, $width, $height) {
  @media (minpixel-ratio: 1.3),
    (min-device-pixel-ratio: 2.6 / 2),
  (-webkit-min-device-pixel-ratio: 1.3),
  (min-device-pixel-ratio: 1.3),
  (min-resolution: 1.3dppx) {
    background-image: url($image) !important;
    background-size: $width $height !important;
  }
}

%unselectable {
  user-select: none;
}

.revert-180 {
  transform: rotate(180deg);
}

.revert-90 {
  transform: rotate(90deg);
}

.revert-negative-90 {
  transform: rotate(-90deg);
}

.whitespace-nowrap {
  white-space: nowrap;
}
