@mixin image-2x($image, $width, $height) {
  @media (min--moz-device-pixel-ratio: 1.3),
    (-o-min-device-pixel-ratio: 2.6/2),
    (-webkit-min-device-pixel-ratio: 1.3),
    (min-device-pixel-ratio: 1.3),
    (min-resolution: 1.3dppx) {
    background-image: url($image) !important;
    background-size: $width $height !important;
  }
}

@mixin image-2x-contains($image) {
  @media (min--moz-device-pixel-ratio: 1.3),
    (-o-min-device-pixel-ratio: 2.6/2),
    (-webkit-min-device-pixel-ratio: 1.3),
    (min-device-pixel-ratio: 1.3),
    (min-resolution: 1.3dppx) {
    background-image: url($image) !important;
    background-size: contain;
  }
}

@mixin retina {
  @media (min--moz-device-pixel-ratio: 1.3),
    (-o-min-device-pixel-ratio: 2.6/2),
    (-webkit-min-device-pixel-ratio: 1.3),
    (min-device-pixel-ratio: 1.3),
    (min-resolution: 1.3dppx) {
    @content;
  }
}

@import 'variables';

%unselectable {
  user-select: none;
}

%hide-text {
  text-indent: 1000%;
  white-space: nowrap;
  overflow: hidden;
}

%proxima-nova-font {
  font-family: 'canada-type-gibson', sans-serif;
  @extend %regular;
}

%oswald-font {
  font-family: 'Oswald', sans-serif !important;
}

%regular {
  font-weight: 300;
}

%medium {
  font-weight: 500;
}

%semi-bold {
  font-weight: 500;
}

%bold {
  font-weight: 500;
}
