.st-color-black {
  color: var(--st-color-black);
}

.st-color-white {
  color: var(--st-color-white);
}

.st-color-dark-grey {
  color: var(--st-color-dark-grey);
}

.st-color-gray {
  color: var(--st-color-grey);
}

.st-color-alert {
  color: var(--st-color-alert);
}

.st-color-alert-deep {
  color: var(--st-color-alert-deep);
}

.st-color-red {
  color: var(--st-color-red);
}

.st-color-sale {
  color: var(--st-color-sale);
}

.st-color-yellow {
  color: var(--st-color-yellow);
}

.st-stroke-black {
  stroke: var(--st-color-black);
}

.st-stroke-white {
  stroke: var(--st-color-white);
}

.st-stroke-gray {
  stroke: var(--st-color-grey);
}

.st-stroke-dark-grey {
  stroke: var(--st-color-dark-grey);
}

.st-stroke-gray-2 {
  stroke: var(--st-color-grey-2);
}

.st-bg-black {
  background: var(--st-color-black);
}

.st-bg-white {
  background: var(--st-color-white);
}

.st-bg-dark-grey {
  background: var(--st-color-dark-grey);
}

.st-bg-gray {
  background: var(--st-color-grey);
}

.st-bg-alert {
  background: var(--st-color-alert);
}

.st-bg-red {
  background: var(--st-color-red);
}

.st-bg-pink {
  background: var(--st-color-pink);
}
