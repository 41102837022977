$mobile-s: 375;
$sm: 580;
$md: 768;
$lg: 992;
$xl: 1200;
$hd: 1440;
$red: red;
$sale-green: #231f1f;
$blue: #231f1f;
$blue-dark: #231f1f;
$grey1: #9b9b9b;
$grey2: #a6a6a6;
$grey3: #d4d4d4;
$grey4: #f2f2f2;
$grey5: #f9f9f9;
$white: #fff;
$black: #231f1f;
$facebook: #3b5998;
$yellow: #ffaf13;
$placeholder: #d9d9d9;
$xmas: #9C1538;
$peak-summer-sale: #ea5c79;
$spring: #f6327f;
$summer: #229dfd;
$black-friday: #fd3a39;
$christmas: #d00321;
$easter: #8069e2;
$school: #eb333a;
$black-friday-2: #e30f0e;
$holiday-sale: $blue;
$back2school: #f5b325;
$special: #231f1f;
$special-price-color: $back2school;
$vd: #ed1535;
$icon-checkbox-mark: '\e91e';
$icon-date-add: '\e909';
$icon-date-remove: '\e90a';
$icon-date: '\e91d';
$icon-facebook-o: '\e91c';
$icon-achievement: '\e900';
$icon-arrow-left: '\e901';
$icon-arrow-right: '\e902';
$icon-audio: '\e903';
$icon-caret-down: '\e904';
$icon-caret-up: '\e905';
$icon-checkmark: '\e906';
$icon-close: '\e907';
$icon-complexity: '\e908';
$icon-delete: '\e90b';
$icon-dots: '\e90c';
$icon-edit: '\e90d';
$icon-facebook: '\e90e';
$icon-flame: '\e90f';
$icon-flesh: '\e910';
$icon-info: '\e911';
$icon-instagram: '\e912';
$icon-lock: '\e913';
$icon-maximize: '\e914';
$icon-minimize: '\e915';
$icon-pause: '\e916';
$icon-play: '\e917';
$icon-star: '\e918';
$icon-time: '\e919';
$icon-twitter: '\e91a';
$icon-unlock: '\e91b';
$theme: 'back2school';
