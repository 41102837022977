.st-w-fill {
  width: 100%;
}

.st-h-fill {
  height: 100%;
}

.st-center {
  text-align: center;
}

.st-right {
  text-align: right;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1 0 auto;
}

.other {
  flex: 0 0 auto;
  z-index: 9;
  background: var(--st-color-white);
}

.st-overflow-x {
  overflow-x: hidden !important;
}
